import _ from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useForm } from "react-hook-form";
import './MainPage.css';

function AlertError({ className, children }: { className?: string, children?: ReactNode }) {
  return <div className={`rounded-md bg-red-50 p-4 ${className}`}>
    <div className="flex">
      <div className="flex-shrink-0">
        {/* Heroicon name: x-circle */}
        <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
        </svg>
      </div>
      <div className="ml-3">
        <div className="text-sm leading-5 font-medium text-red-800">{children}</div>
      </div>
    </div>
  </div>;
}

enum SubmissionStatus {
  ready,
  inProgress,
  error,
  done
}

function Card({ num, locations, register, getValues }: { num: number, locations: string[], register: any, getValues: any }) {
  const values = Object.values(getValues([1, 2, 3, 4, 5, 6].filter(i => i !== num).map(i => `response-${i}`)));
  return <div>
    <img className="block" src={`images/photo${num}.jpg`} alt={`Levallois ${num}`} />
    {locations?.length ?
      <select name={`response-${num}`}
        ref={register({ required: `Merci de préciser votre réponse pour la photo ${num}` })}
        className="mt-1 block mx-auto py-2 px-3 pr-8 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
        <option label=" "></option>
        {locations.filter(location => !values.includes(location)).map((item) => <option key={item} value={item}>{item}</option>)}
      </select> : null}
  </div>;
}

function App() {
  const { register, handleSubmit, errors, getValues } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });
  const [locations, setLocations] = useState<string[]>([]);
  const [submitionStatus, setSubmissionStatus] = useState<SubmissionStatus>(SubmissionStatus.ready);
  const [address, setAddress] = useState<any | null>(null);

  useEffect(() => {
    (async () => {
      const request = await fetch('https://europe-west3-acal-levallois.cloudfunctions.net/contestStreets');
      const content = await request.json();
      setLocations(content);
    })();
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      window.scrollTo(0, 0);
    }
  }, [errors]);

  const onSubmit = async (data: any) => {
    const hasDuplicates = _.uniq([data['response-1'], data['response-2'], data['response-3'], data['response-4'], data['response-5'], data['response-6']]).length !== 6;
    if (hasDuplicates) {
      alert("Vous avez des sélectionné plusieurs fois la même réponse !");
    } else if (!address?.label) {
      alert("Merci de renseigner votre adresse");
    } else {
      try {
        setSubmissionStatus(SubmissionStatus.inProgress);
        const request = await fetch('https://europe-west3-acal-levallois.cloudfunctions.net/contestParticipants', {
          method: 'POST',
          body: JSON.stringify({
            firstName: data['firstName'],
            lastName: data['lastName'],
            email: data['email'],
            phone: data['phone'],
            address: address.label, //data['address'],
            response1: data['response-1'],
            response2: data['response-2'],
            response3: data['response-3'],
            response4: data['response-4'],
            response5: data['response-5'],
            response6: data['response-6'],
          }),
          headers: { 'Content-Type': 'application/json' }
        });
        if (request.ok) {
          setSubmissionStatus(SubmissionStatus.done);
        } else {
          setSubmissionStatus(SubmissionStatus.error);
        }
      } catch (err) {
        console.error(err);
        setSubmissionStatus(SubmissionStatus.error);
      }
    }
  };

  return <>
    <div className="container mx-auto max-w-4xl	my-6">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white shadow sm:rounded-lg px-4 py-6">
          <h1 className="text-center mb-8 text-4xl"><span className="text-7xl">Jeu:</span><br />Levallois d'Antan</h1>

          {submitionStatus === SubmissionStatus.ready &&
            <div>
              <div className="text-xl pt-6 pb-3 text-center leading-10">Retrouvez la description qui correspondant aux photos<br /><span className="text-yellow-500">700€ de bons d’achats sont à gagner !</span></div>

              <div className="grid gap-6 grid-cols-3 md:grid-cols-6 align-middle pb-8">
                <img className="m-auto max-h-24" src="partners/acal.png" alt="ACAL" />
                <img className="m-auto max-h-24" src="partners/cithea.png" alt="Cithea" />
                <img className="m-auto max-h-24" src="partners/credit-mutuel.jpg" alt="Crédit Mutuel" />
                <img className="m-auto max-h-24" src="partners/rotary.png" alt="Rotary" />
                <img className="m-auto max-h-24" src="partners/so-ouest.png" alt="So Ouest" />
                <img className="m-auto max-h-24" src="partners/villa-beausoleil.png" alt="Villa Beausoleil" />
              </div>

              {Object.keys(errors).length > 0 ? <AlertError className="mb-6">{Object.keys(errors).map(key => <p key={key}>{errors[key].message}</p>)}</AlertError> : null}

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 gap-y-8">
                <Card num={1} locations={locations} register={register} getValues={getValues} />
                <Card num={2} locations={locations} register={register} getValues={getValues} />
                <Card num={3} locations={locations} register={register} getValues={getValues} />
                <Card num={4} locations={locations} register={register} getValues={getValues} />
                <Card num={5} locations={locations} register={register} getValues={getValues} />
                <Card num={6} locations={locations} register={register} getValues={getValues} />
              </div>
            </div>}

          {submitionStatus === SubmissionStatus.inProgress && <div className="text-center text-1942 text-xl py-10">
            Soumission en cours...
          </div>}

          {submitionStatus === SubmissionStatus.done && <div className="text-center text-1942 text-xl py-10">
            Participation prise en compte !
          </div>}

          {submitionStatus === SubmissionStatus.error && <div className="text-center text-1942 text-xl py-10">
            Erreur lors de la soumission de votre participation.<br />
            Veuillez réessayer plus tard !
          </div>}
        </div>

        {submitionStatus === SubmissionStatus.ready &&
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 mt-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Informations personnelles</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Ces informations nous permettrons de vous recontacter après le tirage au sort !
              </p>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">Prénom</label>
                    <input
                      ref={register({ required: "Merci de préciser votre prénom" })}
                      type="text" name="firstName" id="firstName" autoComplete="given-name"
                      className="mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Nom</label>
                    <input ref={register({ required: "Merci de préciser votre nom de famille" })} type="text" name="lastName" id="lastName" autoComplete="family-name" className="mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                  </div>

                  <div className="col-span-6 sm:col-span-4">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Adresse email</label>
                    <input ref={register({ required: "Merci de préciser votre adresse email" })} type="email" name="email" id="email" autoComplete="email" className="mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                  </div>

                  <div className="col-span-6 sm:col-span-2">
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Numéro de téléphone</label>
                    <input ref={register({ required: "Merci de préciser votre numéro de téléphone" })} type="text" name="phone" id="phone" autoComplete="phone" className="mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                  </div>

                  <div className="col-span-6">
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700">Adresse postale</label>
                    {/* <input ref={register({ required: "Merci de préciser votre adresse postale" })} type="text" name="address" id="address" autoComplete="address" className="mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" /> */}
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyDBnKojKHhOntc0nc5vAZed1ySiEXh2kTs"
                      apiOptions={{ language: 'fr', region: 'fr' }}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ['fr']
                        }
                      }}
                      selectProps={{
                        className: "mt-1 focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-m",
                        value: address,
                        onChange: setAddress,
                        placeholder: "Entrez votre adresse...",
                        loadingMessage: () => "Chargement...",
                      }}
                    />
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-4">
                  <label className="block text-sm mt-5">
                    <input type="checkbox" name="reglement" ref={register({ required: "Merci de d'accèpter le règlement du jeu concours" })} className="focus:ring-yellow-100 h-4 w-4 text-yellow-600 border-gray-300 rounded" />
                    <span className="text-sm text-gray-700 ml-2">
                      J'ai lu et j'accèpte le <a href="reglement-jeu.pdf" target="_blank" className="text-yellow-600 hover:text-yellow-800">règlement du jeu concours</a>
                    </span>
                  </label>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <button type="submit" className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                      Soumettre ma participation
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>}

        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 mt-6">
          <div className="grid gap-6 grid-cols-3 md:grid-cols-6  align-middle pb-8">
            <img className="m-auto max-h-24" src="partners/acal.png" alt="ACAL" />
            <img className="m-auto max-h-24" src="partners/cithea.png" alt="Cithea" />
            <img className="m-auto max-h-24" src="partners/credit-mutuel.jpg" alt="Crédit Mutuel" />
            <img className="m-auto max-h-24" src="partners/rotary.png" alt="Rotary" />
            <img className="m-auto max-h-24" src="partners/so-ouest.png" alt="So Ouest" />
            <img className="m-auto max-h-24" src="partners/villa-beausoleil.png" alt="Villa Beausoleil" />
          </div>
        </div>

        <div className="text-xs mt-8 text-gray-500 text-justify">
          <p>L’ACAL L’association des commerçants et artisans de Levallois s’engage à protéger les données à caractère personnel de tous ses utilisateurs et plus particulièrement depuis la mise en application du Règlement Général sur la Protection des Données (RGPD).</p>
          <p className="mt-2">En participant au jeu LEVALLOIS D’ANTAN /TIRAGE AU SORT vous consentez à ce que vos données soient enregistrées dans un fichier informatisé par L’ACAL L’association des commerçants et artisans de Levallois. Vos données seront conservées pendant une durée de 10 ans. Les informations nominatives collectées sont destinées à l'usage exclusif de L’ACAL L’association des commerçants et artisans de Levallois. Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée et au Règlement (UE) 2016-679 sur la protection des données, vous disposez d'un droit d'accès, de modification, de rectification et de suppression des informations vous concernant. Vous pouvez exercer ces droits en vous adressant à :  L’association des commerçants et artisans de Levallois, 39 rue Chaptal, 92300 Levallois ou par mail à contact@acal-levallois.fr</p>
        </div>
      </form>
    </div>
  </>;
}

export default App;
